import img1 from '../images/logo/Cyber attack-amico.png';
import img2 from '../images/logo/Operating system-amico.png';
import img3 from '../images/logo/Cell phone-amico.png';
import imgbg1 from '../images/slider/bg_slide_1.png'
import imgbg2 from '../images/slider/bg_slide_1.png'


const heroSliderData = [
    {
        title_1: "Venha construir:",
        title_2: "Aplicativos",
        title_3: "Com a MiData",
        description: "O futuro é agora, venha construir o seu conosco!",
        img: img3,
        imgbg: imgbg1,
        class:'left'
    },
    {
        title_1: "Venha construir:",
        title_2: "ERPs e CRMs",
        title_3: "Com a MiData",
        description: "O futuro é agora, venha construir o seu conosco!",
        img: img2,
        imgbg: imgbg2,
        class:'right'
    },
    {
        title_1: "Fique seguro:",
        title_2: "Proteja seus dados, seus clientes e sua empresa",
        title_3: "Com a MiData",
        description: "O futuro é agora, venha construir o seu conosco!",
        img: img1,
        imgbg: imgbg2,
        class:'left'
    },
    
]

export default heroSliderData;