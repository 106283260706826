import React , {useState} from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';

import img1 from '../../assets/images/generic/icon/app.png'
import img2 from '../../assets/images/generic/icon/erp.png'
import img3 from '../../assets/images/box-item/icon3-recont-post.jpg'
import img4 from '../../assets/images/box-item/icon4-recont-post.jpg'
import imgblog1 from '../../assets/images/generic/APPS.jpg'
import imgblog2 from '../../assets/images/generic/APPS3.jpg'
import imgblogdetail1 from '../../assets/images/generic/APPS1.png'
import imgblogdetail2 from '../../assets/images/generic/APPS2.png'

const AppsDetails = () => {
    const [dataRecent] = useState(
        [
            {
                img: img1,
                title: 'Websites e Landing Pages',
                text : 'Landing Pages, Blogs, Portfólios, Institucionais...',
                link: '/web-details',
            },
            {
                img: img2,
                title: 'ERPs e CRMs',
                text : 'ERP, CRM, Sistemas de Gestão, Sistemas de Vendas...',
                link: '/erp-details',
            },
            {
                img: img3,
                title: 'E-commerce',
                text : 'Lojas Virtuais, Marketplaces, Controle de Estoque...',
                link: '/ecommerce-details',
            },
            {
                img: img4,
                title: 'Cyber Security',
                text : 'Segurança de Dados, Pentest, Análise de Vulnerabilidades...',
                link: '/cyber-details',
            },

        ]
    )

    return (
        <div>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Detalhes de produtos</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Aplicativos</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>                    
            </section>
            <div className="tf-section post-details">
                <div className="themesflat-container">
                    <div className="wrap-flex-box style">
                        <div className="post">
                            <div className="inner-content">
                                <h2 className="title-post">Aplicativos</h2>
                                <div className="divider"></div>
                                <div className="meta-post flex mg-bt-31">
                                    <div className="box">
                                        <div className="inner">
                                            <h6 className="desc">Como funciona</h6>
                                            <p>Deseja saber como funciona o aplicativo?</p>
                                        </div>
                                    </div>
                                    <div className="box left">
                                        {/* <div className="inner boder pad-r-50">
                                            <h6 className="desc">Plataforma</h6>
                                            <p>Android, IOS</p>
                                        </div> */}
                                        <div className="inner mg-l-39 mg-r-1">
                                        <h6 className="desc">Plataformas</h6>
                                            <p>Android, IOS</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="image">
                                    <img src={imgblog1} alt="Axies" style={{ borderRadius: '10px' }} />
                                </div> 
                                <div className="inner-post mg-t-40">
                                    <h3 className="heading mg-bt-16">Entendendo a Necessidade e Definindo Objetivos</h3>  
                                    <p className="mg-bt-24">O primeiro passo crucial para construir um aplicativo é entender a necessidade que você deseja abordar e os objetivos que deseja alcançar. Perguntas importantes incluem: Que problema o aplicativo resolverá? Quem é o público-alvo? Quais recursos e funcionalidades o aplicativo deve ter? Essa etapa é fundamental para garantir que o aplicativo atenda às expectativas e necessidades dos usuários.
                                    </p> 
                                    <div className="image-box">
                                        <img src={imgblogdetail1} alt="Axies" />
                                        <img src={imgblogdetail2} alt="Axies" />
                                    </div>
                                </div>   
                                <div className="inner-post mg-t-22">
                                    <h3 className="heading mg-bt-16">Planejamento e Design</h3>    
                                    <p className="mg-bt-24">Com os objetivos e requisitos definidos, a próxima etapa é o planejamento detalhado do aplicativo. Nossa equipe da MiData trabalhará em estreita colaboração com você para criar um projeto e um design claros e atraentes. O layout, a interface do usuário e a experiência do usuário são projetados com cuidado para garantir que o aplicativo seja fácil de usar e visualmente agradável.</p> 
                                    <div className="image">
                                        <img src={imgblog2} alt="Axies" style={{ borderRadius: '10px' }} />
                                    </div>
                                </div>       
                                <div className="inner-post mg-t-24">
                                    <h3 className="heading mg-bt-16">Desenvolvimento de Backend e Frontend</h3>    
                                    <p> A construção de um aplicativo envolve duas partes principais: o backend e o frontend. O backend é responsável por processar os dados, gerenciar servidores e garantir que o aplicativo funcione corretamente. O frontend é a parte que os usuários veem e interagem, ou seja, a interface do aplicativo. A equipe de desenvolvimento da MiData possui ampla experiência em linguagens de programação e tecnologias modernas para criar um aplicativo confiável, seguro e eficiente. </p>                              
                                </div> 
                                <div className="inner-post mg-t-24">
                                    <h3 className="heading mg-bt-16">Testes e Qualidade</h3>
                                    <p> Antes de lançar o aplicativo para o público, realizamos extensos testes para garantir que ele funcione sem problemas. Isso inclui testes de funcionalidade, testes de usabilidade e testes de compatibilidade em várias plataformas e dispositivos. Nosso objetivo é oferecer um aplicativo livre de bugs e problemas, proporcionando uma experiência perfeita aos usuários. </p>                              
                                </div> 
                                <div className="inner-post mg-t-24">
                                    <h3 className="heading mg-bt-16">Lançamento e Monitoramento</h3>
                                    <p> Com o aplicativo totalmente desenvolvido e testado, é hora do tão esperado lançamento. Nossa equipe auxiliará em todo o processo de lançamento, garantindo que tudo seja feito com sucesso. Além disso, continuaremos monitorando o desempenho do aplicativo após o lançamento, analisando dados e feedback dos usuários para implementar melhorias contínuas. </p>                              
                                </div> 
                                <div className="inner-post mg-t-24">
                                    <h3 className="heading mg-bt-16">Manutenção e Suporte</h3>
                                    <p> Na MiData, acreditamos em relacionamentos de longo prazo com nossos clientes. Portanto, oferecemos serviços de manutenção e suporte contínuos para garantir que o aplicativo permaneça atualizado, seguro e em pleno funcionamento. Se surgirem novas ideias ou recursos futuros, nossa equipe estará pronta para ajudá-lo a expandir o potencial do seu aplicativo. </p>                              
                                    <p> Construir um aplicativo é uma jornada emocionante, e na MiData, estamos comprometidos em fornecer soluções de alta qualidade que atendam aos seus objetivos e superem as expectativas dos usuários. Se você está pronto para tornar sua ideia de aplicativo uma realidade, entre em contato conosco e comece essa emocionante jornada de construção de aplicativos com a MiData ao seu lado! </p>                              
                                </div> 
                                <div className="divider d2"></div>
                                <div id="comments">
                                    <h3 className="heading mg-bt-23">
                                        Vamos conversar
                                    </h3>
                                    <form action="#" method="post" id="commentform" className="comment-form" >
                                        <fieldset className="name">
                                            <input type="text" id="name" placeholder="Nome" className="tb-my-input" name="name" tabIndex="2" aria-required="true" required />
                                        </fieldset>
                                        <fieldset className="email">
                                            <input type="email" id="email" placeholder="Email *" className="tb-my-input" name="email" tabIndex="2" aria-required="true" required />
                                        </fieldset>
                                        <fieldset className="message">
                                            <textarea id="message" name="message" rows="4" placeholder="Mensagem" tabIndex="4" aria-required="true" required />
                                        </fieldset>
                                        <div className="btn-submit mg-t-36">
                                            <button className="tf-button-submit" type="submit">
                                                Enviar
                                        </button>
                                        </div>
                                    </form>
                                </div>          
                            </div>
                        </div>
                        <div className="side-bar details">
                            <div className="widget widget-recent-post mg-bt-43">
                                <h3 className="title-widget mg-bt-23">Relacionados</h3>
                                <ul>
                                    {
                                        dataRecent.map((item, index) => (
                                            <li key={index} className="box-recent-post">
                                                <div className="box-feature" style={{ backgroundColor: '#7e1dd6', borderRadius: '10px', width: '60px', height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Link to={item.link}>
                                                        <img src={item.img} alt="Axies" />
                                                    </Link>
                                                </div>
                                                <div className="box-content">
                                                    <Link to={item.link} className="title-recent-post">{item.title}</Link>
                                                    <span className="sub-recent-post">{item.text}</span>
                                                </div>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                            {/* <div className="widget widget-tag style-1">
                                <h3 className="title-widget mg-bt-23">Popular Tag</h3>
                                <ul>
                                    {
                                        dataTags.map((item,index) => (
                                            <li key={index}><Link to="/blog" className="box-widget-tag">{item.name}</Link></li>
                                        ))
                                    }
                                </ul>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
export default AppsDetails;
