import atelie from '../images/companies/atelie.jpg'
import ensinio from '../images/companies/ensinio.png'
import foxbit from '../images/companies/foxbit.jpg'
import globo from '../images/companies/globo.png'
import mkt from '../images/companies/mkt.jpeg'
import mm from '../images/companies/mm.png'
import neoenergia from '../images/companies/neoenergia.jpg'
import pagai from '../images/companies/pagai.png'
import reverde from '../images/companies/reverde.jpg'
import cbf from '../images/companies/cbf.png'
import oi from '../images/companies/oi.png'

const topSellerData = [
    {
        img: reverde,
        name: "Reverde",
        price: "214.2 ETH",
        classPadding: ""
    },
    {
        img: globo,
        name: "Globo" ,
        price: "205.43 ETH",
        classPadding: "pl-17"
    },
    {
        img: pagai,
        name: "Pagai" ,
        price: "170.3 ETH",
        classPadding: "pl-34"
    },
    {
        img: mkt,
        name: "MKT Virtual" ,
        price: "120.7 ETH",
        classPadding: "pl-51"
    },
    {
        img: cbf,
        name: "CBF" ,
        price: "214.2 ETH",
        classPadding: "pl-68"
    },
    {
        img: foxbit,
        name: "Foxbit" ,
        price: "82.79 ETH",
        classPadding: "pl-68"
    },
    {
        img: pagai,
        name: "Pagai" ,
        price: "68.2 ETH",
        classPadding: ""
    },
    {
        img: oi,
        name: "Oi" ,
        price: "205.43 ETH",
        classPadding: "pl-17"
    },
    {
        img: mm,
        name: "MadeiraMadeira" ,
        price: "52.8 ETH",
        classPadding: "pl-17"
    },
    {
        img: neoenergia,
        name: "Neoenergia" ,
        price: "38.4 ETH",
        classPadding: "pl-34"
    },
    {
        img: ensinio,
        name: "Ensinio" ,
        price: "29.2 ETH",
        classPadding: "pl-51"
    },
    {
        img: atelie,
        name: "Crispin Berry" ,
        price: "214.2 ETH",
        classPadding: ""
    },
]

export default topSellerData;