import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { whatsappLogo, whatsappIcon } from './styles.ts';

const WhatsAppLogo = () => {
  // Implemente o código aqui para manipular o clique na logo do WhatsApp
  const handleWhatsAppClick = () => {
    window.open(
      'https://api.whatsapp.com/send?phone=5575991078460&text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20solicitar%20um%20or%C3%A7amento%20para%20o%20meu%20projeto%20de%20site%20com%20voc%C3%AAs.',
      '_blank',
    );
  };

  return (
    <div
      style={whatsappLogo}
      onClick={handleWhatsAppClick}
    >
      <FaWhatsapp style={whatsappIcon} />
    </div>
  );
};

export default WhatsAppLogo;
