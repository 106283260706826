import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {BrowserRouter} from 'react-router-dom'
import ScrollToTop from './ScrollToTop';
import WhatsAppLogo from '../src/components/Whatsapp/index';

ReactDOM.render(
  <BrowserRouter >
    <WhatsAppLogo />
    <ScrollToTop />
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);
