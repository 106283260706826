export const whatsappLogo: React.CSSProperties = {
  position: 'fixed',
  bottom: '20px',
  right: '70px',
  width: '50px',
  height: '50px',
  backgroundColor: 'green',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  zIndex: 9999,
};

export const whatsappIcon: React.CSSProperties = {
  color: 'white',
  fontSize: '30px',
};
