import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';

import img1 from '../../assets/images/generic/icon/app.png'
import img2 from '../../assets/images/generic/icon/erp.png'
import img3 from '../../assets/images/box-item/icon3-recont-post.jpg'
import img4 from '../../assets/images/box-item/icon4-recont-post.jpg'
import imgblog1 from '../../assets/images/generic/WEB1.jpg'
import imgblog2 from '../../assets/images/generic/WEB3.jpg'
import imgblogdetail1 from '../../assets/images/generic/WEB.png'
import imgblogdetail2 from '../../assets/images/generic/WEB2.png'

const WebDetails = () => {
    const [dataRecent] = useState(
        [
            {
                img: img1,
                title: 'Aplicativos',
                text: 'Aplicativos Android e IOS, Aplicativos Híbridos, Aplicativos Nativos...',
                link: '/app-details',
            },
            {
                img: img2,
                title: 'ERPs e CRMs',
                text: 'ERP, CRM, Sistemas de Gestão, Sistemas de Vendas...',
                link: '/erp-details',
            },
            {
                img: img3,
                title: 'E-commerce',
                text: 'Lojas Virtuais, Marketplaces, Controle de Estoque...',
                link: '/ecommerce-details',
            },
            {
                img: img4,
                title: 'Cyber Security',
                text: 'Segurança de Dados, Pentest, Análise de Vulnerabilidades...',
                link: '/cyber-details',
            },

        ]
    )
    return (
        <div>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Detalhes de produtos</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>WebSites</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="tf-section post-details">
                <div className="themesflat-container">
                    <div className="wrap-flex-box style">
                        <div className="post">
                            <div className="inner-content">
                                <h2 className="title-post">WebSites e Landing Pages</h2>
                                <div className="divider"></div>
                                <div className="meta-post flex mg-bt-31">
                                    <div className="box">
                                        <div className="inner">
                                            <h6 className="desc">Como funciona</h6>
                                            <p>Desenvolvimento de Websites e Landing Pages</p>
                                        </div>
                                    </div>
                                    <div className="box left">
                                        {/* <div className="inner boder pad-r-50">
                                            <h6 className="desc">Plataforma</h6>
                                            <p>Android, IOS</p>
                                        </div> */}
                                        <div className="inner mg-l-39 mg-r-1">
                                            <h6 className="desc">Plataformas</h6>
                                            <p>
                                                sites, landing pages, blogs, portfólios, institucionais, etc...
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="image">
                                    <img src={imgblog1} alt="Axies" style={{ borderRadius: '10px' }} />
                                </div>
                                <div className="inner-post mg-t-40">
                                    <h3 className="heading mg-bt-16">Compreendendo a Identidade e Objetivos</h3>
                                    <p className="mg-bt-24">O primeiro passo para construir um website ou landing page é compreender a identidade da sua marca MiData e os objetivos específicos que deseja alcançar. Quais são os valores centrais da sua empresa? Qual mensagem você deseja transmitir aos visitantes? Nossa equipe da MiData Web Solutions trabalhará em estreita colaboração com você para entender suas necessidades e garantir que o projeto reflita a essência da sua marca.
                                    </p>
                                    <div className="image-box">
                                        <img src={imgblogdetail1} alt="Axies" />
                                        <img src={imgblogdetail2} alt="Axies" />
                                    </div>
                                </div>
                                <div className="inner-post mg-t-22">
                                    <h3 className="heading mg-bt-16">Planejamento e Design</h3>
                                    <p className="mg-bt-24">Com uma compreensão clara de sua identidade e objetivos, iniciamos o planejamento detalhado do website ou landing page. Criamos um design atraente e funcional, com foco na experiência do usuário. A navegação intuitiva, a disposição inteligente do conteúdo e um design esteticamente agradável são elementos essenciais que garantem que seus visitantes tenham uma experiência agradável e memorável.</p>
                                </div>
                                <div className="inner-post mg-t-24">
                                    <h3 className="heading mg-bt-16">Desenvolvimento Responsivo</h3>
                                    <p> Nossa equipe de desenvolvimento na MiData Web Solutions possui ampla experiência em linguagens de programação modernas e tecnologias avançadas para criar websites e landing pages responsivos. Isso significa que o seu site se adaptará perfeitamente a diferentes dispositivos, como smartphones, tablets e computadores, proporcionando uma experiência consistente em todas as plataformas. </p>
                                </div>
                                <div className="inner-post mg-t-24">
                                    <h3 className="heading mg-bt-16">Conteúdo Relevante e Atrativo</h3>
                                    <p> Um website ou landing page impactante precisa de conteúdo relevante e envolvente. Nossa equipe de redatores e designers trabalhará em conjunto para criar um conteúdo persuasivo e visualmente atraente, destacando a marca MiData. Isso ajudará a comunicar sua mensagem de forma clara e eficaz, além de incentivar os visitantes a realizarem ações desejadas, como fazer uma compra, entrar em contato ou assinar uma newsletter. </p>
                                </div>
                                <div className="image mg-t-24">
                                    <img src={imgblog2} alt="Axies" style={{ borderRadius: '10px' }} />
                                </div>
                                <div className="inner-post mg-t-24">
                                    <h3 className="heading mg-bt-16">Otimização para Mecanismos de Busca (SEO)</h3>
                                    <p> Entendemos a importância do SEO para aumentar a visibilidade do seu website ou landing page nos resultados de pesquisa. Nossos especialistas em SEO otimizarão o conteúdo e a estrutura do site para melhorar o posicionamento nos mecanismos de busca, atraindo mais tráfego qualificado para a marca MiData. </p>
                                </div>
                                <div className="inner-post mg-t-24">
                                    <h3 className="heading mg-bt-16">Manutenção e Suporte</h3>
                                    <p> Nossa parceria não termina após o lançamento. A MiData oferece serviços de manutenção contínua para garantir que seu site ou landing page permaneça atualizado e funcione sem problemas. Além disso, podemos ajudá-lo a acompanhar o desempenho do site por meio de análises detalhadas e implementar melhorias conforme necessário. </p>
                                    <p> Construir um website ou landing page poderoso é uma estratégia essencial para o sucesso online da marca MiData. Com a MiData ao seu lado, você terá uma equipe dedicada e especializada trabalhando para transformar suas ideias em um website ou landing page envolvente, que impulsionará o crescimento do seu negócio e deixará uma impressão duradoura em seus visitantes. Entre em contato conosco hoje mesmo para iniciar essa jornada de construção digital com a MiData. </p>
                                </div>
                                <div className="divider d2"></div>
                                <div id="comments">
                                    <h3 className="heading mg-bt-23">
                                        Vamos conversar
                                    </h3>
                                    <form action="#" method="post" id="commentform" className="comment-form" >
                                        <fieldset className="name">
                                            <input type="text" id="name" placeholder="Nome" className="tb-my-input" name="name" tabIndex="2" aria-required="true" required />
                                        </fieldset>
                                        <fieldset className="email">
                                            <input type="email" id="email" placeholder="Email *" className="tb-my-input" name="email" tabIndex="2" aria-required="true" required />
                                        </fieldset>
                                        <fieldset className="message">
                                            <textarea id="message" name="message" rows="4" placeholder="Mensagem" tabIndex="4" aria-required="true" required />
                                        </fieldset>
                                        <div className="btn-submit mg-t-36">
                                            <button className="tf-button-submit" type="submit">
                                                Enviar
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="side-bar details">
                            <div className="widget widget-recent-post mg-bt-43">
                                <h3 className="title-widget mg-bt-23">Relacionados</h3>
                                <ul>
                                    {
                                        dataRecent.map((item, index) => (
                                            <li key={index} className="box-recent-post">
                                                <div className="box-feature" style={{ backgroundColor: '#7e1dd6', borderRadius: '10px', width: '60px', height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Link to={item.link}>
                                                        <img src={item.img} alt="Axies" />
                                                    </Link>
                                                </div>
                                                <div className="box-content">
                                                    <Link to={item.link} className="title-recent-post">{item.title}</Link>
                                                    <span className="sub-recent-post">{item.text}</span>
                                                </div>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                            {/* <div className="widget widget-tag style-1">
                                <h3 className="title-widget mg-bt-23">Popular Tag</h3>
                                <ul>
                                    {
                                        dataTags.map((item,index) => (
                                            <li key={index}><Link to="/blog" className="box-widget-tag">{item.name}</Link></li>
                                        ))
                                    }
                                </ul>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
export default WebDetails;
