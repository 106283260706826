import React , { useState } from 'react';
import { Link } from 'react-router-dom'
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { Accordion } from 'react-bootstrap-accordion'

const FAQ = () => {
    const [data] = useState(
        [
            {   key: "0",
                show: "show",
                title: 'Como funciona um orçamento?',
                text: 'Ao solicitar um orçamento, você receberá um e-mail com as devidas instruções para orçamento de seu projeto. Após o envio do orçamento, você receberá um e-mail com o valor do projeto e o prazo de entrega. Após a confirmação do pagamento, você receberá um e-mail com o prazo de entrega do projeto. Após a entrega do projeto, você receberá um e-mail com o link para download do projeto.',
            },
            {
                key: "1",
                title: 'Quanto tempo leva para receber meu projeto?',
                text: 'O prazo de entrega do projeto varia de acordo com o tipo de projeto. Depende também da complexidade do projeto. O prazo de entrega do projeto é informado no e-mail de confirmação do pagamento.',
            },
            {
                key: "2",
                title: 'Como funciona o pagamento?',
                text: 'O pagamento é feito em parcelas, na entrada e na entrega do projeto. O pagamento é feito através de boleto bancário ou pix.',
            },
            {
                key: "3",
                title: 'Quanto tempo tenho de suporte?',
                text: 'O suporte é feito durante o desenvolvimento do projeto. Após a entrega do projeto, o suporte é feito por 30 dias.',
            },
        ]
    )
    return (
        <div>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">FAQ</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>FAQ</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>                    
            </section>
            <section className="tf-section wrap-accordion">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="tf-title-heading ct style-2 fs-30 mg-bt-10">
                                Perguntas Frequentes
                            </h2>
                            <h5 className="sub-title help-center mg-bt-32 ">
                                Se você não encontrar a resposta para sua pergunta, entre em contato conosco.
                            </h5>
                        </div>
                        <div className="col-md-12">
                            <div className="flat-accordion2">
                                {
                                    data.map((item,index) => (
                                        <Accordion key={index} title={item.title} >
                                            <p>{item.text}</p>
                                        </Accordion>
                                    ))
                                }                             
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default FAQ;
