import React from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import heroSliderData from '../assets/fake-data/data-slider';
import Slider from '../components/slider/Slider';
// import liveAuctionData from '../assets/fake-data/data-live-auction';
// import LiveAuction from '../components/layouts/LiveAuction';
import TopSeller from '../components/layouts/TopSeller';
import topSellerData from '../assets/fake-data/data-top-seller'
import PopularCollection from '../components/layouts/PopularCollection';
import popularCollectionData from '../assets/fake-data/data-popular-collection';
import Create from '../components/layouts/Create';

const Home01 = () => {

    return (
        <div className='home-1'>
            <Header />
            <Slider data={heroSliderData} />
            <Create />
            <PopularCollection data={popularCollectionData} />
            {/* <LiveAuction data={liveAuctionData} /> */}
            <TopSeller data={topSellerData} />
            {/* <TodayPicks data={todayPickData} /> */}
            <Footer />
        </div>
    );
}

export default Home01;
